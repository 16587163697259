body {
  margin: 0;
  background: rgb(250, 250, 250);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* @media (max-width: 1600px) {
  html {
      font-size: 80%;
  }
} */

@media (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1600px) and (max-width: 1919px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1599px) {
  html {
    font-size: 11.5px;
  }
}

#like-btn:focus {
  border: 1px #174ae4 solid;
}
#dislike-btn:focus {
  border: 1px #174ae4 solid;
}
#copy-btn:focus {
  border: 1px #174ae4 solid;
}